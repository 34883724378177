@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: white;
}
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  max-width: 1230px;
}

.Mui-selected {
  background-color: #fff !important;
  border-radius: 27px !important;
  box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.08) !important;
  color: rgba(100, 154, 233, 1) !important;
}

.MuiTabs-indicator {
  display: none !important;
}

.MuiButtonBase-root {
  text-transform: none !important;
  font-family: Lato, sans-serif !important;
  /* font-color: #475569 !important; */
  font-weight: bold !important;
  font-size: 14px !important;
  min-height: 54px !important;
}

.Mui-selected .MuiTab-iconWrapper {
  filter: invert(61%) sepia(22%) saturate(1104%) hue-rotate(178deg)
    brightness(92%) contrast(99%);
}

.MuiTouchRipple-root {
  border-radius: 27px !important;
}

.rdp,
.rdp-head,
.rdp-tbody,
.rdp-table {
  margin: 0 !important;
  width: 100% !important;
}
.DayPickerInput-Overlay {
  z-index: -100000000 !important;
}
.rdp-button {
  font-family: Lato !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #475569 !important;
}

.rdp-nav_button {
  color: #649ae9 !important;
}

.rdp-month {
  max-width: 500px !important;
  width: 100% !important;
}

.rdp-table {
  max-width: 500px !important;
  width: 100% !important;
}

.rdp-row {
  height: 42px !important;
}
.rdp-day_selected {
  background-color: #649ae9 !important;
  color: white !important;
}

:root {
  --rdp-outline: none !important;
  --rdp-outline-selected: none !important;
}

.rdp-caption_label {
  font-family: Montserrat !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #220e45 !important;
}

.rdp-day {
  display: initial !important;
}

.rdp-head_cell {
  font-family: Lato !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  color: #707070 !important;
}

ul.a {
  list-style-type: circle;
  list-style-position: inside;
}

